import Home from './Home.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

function App() {
	return (
		<div>
			<Home />
		</div>
	)
}

export default App

import { Row, Col } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'

const Home = () => {
	return (
		<Container className='text-center'>
			<Row className='bigContainer'>
				<Col className='my-auto'>
					<div className='namebg py-3'></div>
					<div className='py-5'>
						<a href='https://www.linkedin.com/in/kaisa-heinonen/'>
							<i class='fab fa-linkedin fa-2x'></i>
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	)
}
export default Home
